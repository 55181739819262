"use client";

import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Paragraph } from "../Typography";
import { EditorMenuBar } from "./EditorMenuBar";

import "./style.css";

interface RichTextEditorProps {
  value?: string;
  style?: CSSProperties;
  maxLength?: number;
  onChange?: (v: string) => void;
  autoFocus?: boolean;
}

export function RichTextEditor({
  value = "",
  style,
  maxLength,
  onChange,
  autoFocus,
}: RichTextEditorProps) {
  const [isFocused, setIsFocused] = useState(false);
  const lengthCounter = value?.length || 0;
  const editor = useEditor({
    extensions: [
      StarterKit,
      Table.configure({ resizable: false }),
      TableRow,
      TableHeader,
      TableCell,
      Link.configure({
        openOnClick: true,
        autolink: true,
        linkOnPaste: true,
      }),
    ],
    // There's a known bug where the autofocus prop does not work,
    // need to unset it for false to work.
    ...(autoFocus ? { autofocus: true } : {}),
    editable: true,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onUpdate: async ({ editor }) => {
      const htmlValue = editor?.getHTML();

      onChange?.(htmlValue);
    },
  });

  useEffect(() => {
    if (editor) {
      const commandChain = editor.chain().setContent(value);
      if (autoFocus) {
        commandChain.focus().run();
      } else {
        commandChain.run();
      }
    }
  }, [editor]);

  return (
    <div>
      <div
        style={style}
        className={clsx("rich-text-editor", { focused: isFocused })}
      >
        <EditorMenuBar editor={editor} />
        <Paragraph
          style={{
            margin: 0,
          }}
        >
          <EditorContent className="rich-text-editor-content" editor={editor} />
        </Paragraph>
      </div>
      {maxLength && maxLength > 0 && (
        <div
          style={style}
          className={clsx("rich-text-editor-counter", {
            error: lengthCounter > maxLength,
          })}
        >
          {lengthCounter} / {maxLength}
        </div>
      )}
    </div>
  );
}
