import { InfoCircleOutlined } from "@ant-design/icons";
import { GetProps, Tooltip } from "antd";
import { Text } from "./Typography";

interface TooltipIconProps extends Omit<GetProps<typeof Tooltip>, "children"> {
  icon?: React.ReactNode;
  iconClassName?: string;
}

function TooltipIcon({
  icon,
  iconClassName,
  title,
  ...toolTipProps
}: TooltipIconProps) {
  if (title === undefined) {
    return null;
  }

  return (
    <Tooltip title={title} {...toolTipProps}>
      <div className={iconClassName}>
        <Text type="secondary">{icon ?? <InfoCircleOutlined />}</Text>
      </div>
    </Tooltip>
  );
}
export { Tooltip, TooltipIcon };
